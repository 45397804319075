.newGoal{
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.newGoal_list{
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;
    margin: auto;
    margin-top: 80px;

}
.newGoal_list_item{
    height: 40px;
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px var(--blueFlit) solid;
    border-radius: 30px;
    color: var(--blueFlit);
    font-weight: 500;
}
.newGoal_list_item_button{
    background: none;
    font-weight: 500;
    width: 100%;
    color: var(--blueFlit);
    text-transform: capitalize;
    border-radius: 30px;

}

.type_active{
    background-color:var(--lightGreenFlit) ;
    color: white;

}
.newGoal_time_list{
    width: 90%;
    margin: auto;
    margin-top:5px;
}
.newGoal_time_list_item{
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    justify-content: space-around;

}
.newGoal_time_ionitem{
    --border-radius:30px;
    --background: var(--lightGreenFlit);
    --color: white;

}
.newGoal_time_list_label{
    font-size: 14px!important;
}
.newGoal_time_checkbox{
    --border-radius:20px;
    --size:20px
}
.newGoal_time_dateTime{
    border-radius: 5px;
}
.newGoal_time_date{
    background-color: var(--blueFlit);
    border-radius: 5px;
    border: none;
    color: black;
    padding-left: 5px;
    height: 30px;
}
.newGoal_time_date_text{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    color: white;
}

.newGoal_modal_button{
    --background: var(--blueFlit);
    border:solid 1px white;
    border-radius: 30px;
    width: 40%;
}
.newGoal_modal{
    --width:90%;
    --height:350px;
    color: var(--blueFlit);
}
.newGoal_footer{
    display: flex;
    justify-content: center;
}

@media screen and (min-width:1024px) {
    .newGoal{
        width: 50%;
        margin: auto;
    }
    .newGoal_modal{
        --width:20%;
    }
}
