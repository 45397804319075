/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** Flit **/
  --blueFlit: #0d3857;
  --greenFlit: #007473;
  --lightGreenFlit: #65b76f;
  --inputgrey: #ebebeb;
  --strava: #fc4c02;
  --garmin: #000;
  --beige:#F6F3F3;
  --grey:#8A9CAB;
  --greenNew:#DFF0DF;
}

/*  ION PROPERTY */

.title_form_fredoka{
  font-weight: 700;
  font-family: 'Fredoka', sans-serif;
  font-size: 40px;
}
.tab-nav {
  margin-bottom: constant(safe-area-inset-bottom);
}

ion-checkbox {
  --border-radius: 5px;
  --size: 17px;
  --background-checked: var(--lightGreenFlit);
  --border-color-checked: var(--lightGreenFlit);
  --checkmark-width: 3px;
  --border-color: white;
}

ion-content {
  --background: white;
  --keyboard-offset: 0px;

}
ion-page {
  --keyboard-offset: 0px;
}
ion-footer {
  --keyboard-offset: 0px;
}
ion-header {
  --background: white;
  --color:black
}
ion-toolbar {
  --background: white;
  --color:black;
  --border-style: none;
  --keyboard-offset: 0px;
}
ion-button {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  --color-activated:none;
  --background-activated:none
}
ion-fab-button{
  --color-activated:none;
  --background-activated:none
}
.div_principal{
  padding-right: 15px;
  padding-left: 15px;
}

h1{
  font-size: 30px;
}
h2{
  font-size: 22px;
}
p{
  font-size: 16px;
}
.img_form{
  width: 200px;
}

h1,
h2{
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  color: var(--blueFlit);
}
h3,h4,h5{
  font-family: "Nunito", sans-serif;
  color: var(--blueFlit);

}
p,
ion-text,
button,
li {
  font-family: "Nunito", sans-serif;
  color: var(--blueFlit);

}

a {
  text-decoration: none;
  color: var(--blueFlit);
  font-family: "Nunito", sans-serif;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.content_form{
  --padding-top: 44px;
}
.input {
  --border-radius: 5px;
  --min-height: 45px !important;
  --background: white;
}
.header {
  --background: var(--blueFlit);
  font-family: "Nunito", sans-serif;
}
.headerBackButton {
  --color: var(--blueFlit);
  --icon-margin-end: 10px;
}
.app_content {
  --background: white;
}
.app_toolbar {
  --background: white;
  font-family: "Nunito", sans-serif;
}

.app_backButtons {
  --color: var(--blueFlit);
}
.app_header_title {
  color: var(--blueFlit);
}
@media screen and (min-width: 1024px) {
  ion-header {
    display: none;
  }
  .footer_global {
    width: 35%;
    margin: auto;
    margin-top: 30px;
  }
}
@media screen and (min-width: 320px) {
  .footer_title{
    height: 50px;
  }
  h2{
    font-size: 22px;
  }
  p{
    font-size: 11Px;
  }
  h3{
    font-size: 15px;
  }
  h4{
    font-size: 18px;
  }
  h5{
    font-size: 14px;
  }

}

@media screen and (min-width: 390px) {

  h1{
    font-size: 40px;
  }
  h2{
    font-size: 28px;
  }
  p{
    font-size: 15px;
  }
  h3{
    font-size: 20px;
  }
  h4{
    font-size: 22px;
  }
  h5{
    font-size: 18px;
  }
  .img_form{
    width: 250px;
  }
}
@media screen and (min-width: 410px){

  h1{
    font-size: 46px;
  }
  h2{
    font-size: 32px;
  }
  p{
    font-size: 17px;
  }
  .img_form{
    width: 250px;
  }
}