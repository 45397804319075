
.tabBar_button {
    --background: var(--beige);
    font-size: 12px;
    --color-selected: var(--lightGreenFlit);
    font-family: 'Nunito Sans', sans-serif;

}
.tabs_none{
    display: none;
}
.tabBar_mobile {
    --background: var(--beige);

}

.tabs_icon {
    font-size: 30px;
    margin-top: 5px;
}

.icon_tabBar {
    width: 35%;
    color: var(--blueFlit);
}

.tabBar_label {
    color: var(--blueFlit);
    font-size: 10px;
}

.tabBar_label_green {
    color: var(--lightGreenFlit);
    font-size: 10px;
    font-weight: bold;
}

.tabBar_fab_button {
    --background: linear-gradient(
            180deg,
            rgba(0, 116, 115, 1) 0%,
            rgba(0, 116, 115, 1) 35%,
            rgba(101, 183, 111, 1) 100%
    );


}

.fab_icon {
    transform: rotate(90deg);
}

.tabBar_global {
    box-shadow: 0px -12px 42px -1px rgba(0, 0, 0, 0.1);
    --background: transparent;
}

.tabBar_desktop {
    display: none;
}


@media screen and (min-width: 700px) {


    .tabBar_button {
        --color: var(--blueFlit);
        --background: white;
        color: var(--blueFlit);
        --color-selected: var(--blueFlit);
    }
    .tabs_icon {
        color: var(--blueFlit);
    }
    .icon_tabBar {

        width: 30%;
        color: var(--blueFlit);
    }

}

