.skeletonWeekPlan_infoList{
    display: flex;
    justify-content: space-around;
    margin-left: 25px;
    width: 90%;
}
.skeletonWeekPlan_infoList_item{
    width: 100%;
    margin-top: 30px;
}
.skeletonWeekPlan_list{
    margin-top: 30px;
}