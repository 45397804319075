.total_times {
    display: flex;
}

.minuteur_typeof {
    text-align: center;
    margin-top: 0;
    font-weight: bold;
}

.minuteur_totalTime_content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.minuteur_goalsTotalTime {
    margin: 0;
    padding-left: 5px;
}

.minuteur_inside_progressBar_box {
    text-align: center;
}

.minuteur_inside_progressBar_box_name_green {
    font-weight: bold;
    color: var(--lightGreenFlit);
}
.minuteur_inside_progressBar_box_name_red{
    font-weight: bold;
    color: red;
}


.minuteur_inside_progressBar_box_timer {
    font-size: 50px;
}

.minuteur_inside_progressBar_box_allure {
    color: var(--grey);
}

.minuteur_next_step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.minuteur_next_step_title {
    margin: 0;
    color: var(--grey);

}
.minuteur_step_text{
    text-align: center;
    margin: 10px 0 0 0;
}
.minuteur_next_step_subtitle {
    margin: 5px 0 0 0;
    color: var(--grey);
}
.minuteur_button_play_pause_green{
    color: var(--lightGreenFlit);
    font-size: 50px;
    margin: 20px auto 0 auto;
}
.minuteur_button_play_pause_red{
    color: red;
    font-size: 50px;
    margin: 20px auto 0 auto;
}
