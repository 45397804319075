.analyse{
    padding-right: 15px;
    padding-left: 15px;
}

.analyse_logo{
    width: 300px;
    margin: auto;
}
.analyse_text{
    text-align: center;
    margin-top: 60px;
}