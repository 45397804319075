.footer{

    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.footer_button{
    --background: linear-gradient(
           90deg,
            rgba(0, 116, 115, 1) 0%,
            rgba(0, 116, 115, 1) 35%,
            rgba(101, 183, 111, 1) 100%
    );
    width:50%;
    --background-activated: none;
    --border-radius: 30px;
    font-size: 14px;
    font-weight: bold;
    text-transform:none
}

@media screen and (min-width: 390px) {
   .footer_button{
       width: 50%;
       font-size: 16px;
       --text-transform:none

   }

}