.day_global_title{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
}
.day_title_content{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.day_star_content{
    display: flex;
    align-items: center;

}
.avoid_header{
    width: 38px;
    background: red;
}
.day_toolbar{
    display: flex;
    justify-content: space-between;
}
.day_empty_div{
    width: 20%;
}
.day_buttons_header{
    width: 20%;
}
