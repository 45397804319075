.weekBilanSuccess{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    color: var(--blueFlit);
    height: 80%;

}
.weekBilanSuccess_logo{
    width: 300px;
    margin: auto;
}
.weekBilanSuccess_text{
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    width: 50%;
    align-items: center;
}

.weekBilanSuccess_none{
    display:none;
}
.weekBilanSuccess_subTitle{
    text-align: center;
    margin-top: 24px;
    font-size: 1.2rem;

}
.weekBilanSuccess_spinner{
    transform: scale(1.5);
}
   
.weekBilanSuccess_textContent{
   text-align: center;
   width: 90%;
   margin-top: 30px;
}

