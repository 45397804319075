.fab_button_content {
  color: var(--blueFlit) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
    margin-bottom: 40px;
}
.fab_button_content_title {
  margin-bottom: 30px;
}
.fab_button_modal{
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  --height:80%;
  --border-radius:20px;
  margin: auto;
}
.fab_races{
  margin-bottom: 10px;
}
.fab_input{
  background-color: var(--beige) !important;
}
.fab_dispo_list{
  width:90%;
}
.icon_fabButton{
  --font-size: 30px;
}
.fabButton_weekOfRaces{
  background-color: var(--beige);
  border: none;
  text-align: center;
  border-radius: 30px;
  font-weight: bold;
  height: 30px;
  width: 40%;
}