.noviceLevel{
    padding-left: 15px;
    padding-right: 15px;
margin-bottom: 30px}
.noviceLevel_title{
    text-align: center;
    color: var(--blueFlit);
    margin: 0;

}
.noviceLevel_subtitle{
    text-align: center;
    color: var(--blueFlit);

}
.noviceLevel_content_list_item{
    border: 0.5px solid var(--lightGreenFlit);
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 15px;
}
.novice_active{
    background-color: var(--lightGreenFlit);
    color: white;
}
.noviceLevel_content_list_item_title{
    font-weight: bold;
    margin-block: 0;
    margin-bottom: 5px;
}
.noviceLevel_content_list_item_text{
    margin-block: 0;


}

@media screen and (min-width: 390px){
    .noviceLevel_content_list_item{
        padding: 15px;
    }
}