.contact_title {
    display: flex;
    justify-content: space-around;
    width: 60%;
    margin: auto;
}

.contact_content {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 200px;

}

.contact_title_flit {
    color: var(--lightGreenFlit);
    font-style: italic;
    font-size: 2rem;
}

.contact_title_support {
    color: var(--greenFlit);
    padding-left: 5px;
    font-size: 2rem;
}

.contact_button_content {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;

}

.contact_button {
    --background: none
}

.contact_button_icon {
    transform: rotate(45deg);
    color: var(--blueFlit);
}

/* Desktop */

.contact_desktop_form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
    width: 100%;
    height: 80%;
}

.contact_desktop {
    height: 100%;
    display: flex;
}
.contact_desktop_label{
    font-weight: bold;

}
.contact_desktop_input{
    background: lightgrey;
    padding-left: 20px;
    color: var(--blueFlit);
    outline: none;
    border: none;
    border-radius: 20px;
    height: 40px;
}
.contact_desktop_area{
 background: white;

}
.contact_desktop_button_div{
    display: flex;
    justify-content:center ;
    align-content: center;
    margin-top: 20px;
}
.contact_desktop_button{
    border:0.5px solid var(--greenFlit);
    border-radius: 30px;

    background: linear-gradient(
            180deg,
            rgba(0, 116, 115, 1) 0%,
            rgba(0, 116, 115, 1) 35%,
            rgba(101, 183, 111, 1) 100%
    );

    width: 200px;
    height: 40px;
    color:white;
}