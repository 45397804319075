.howManyTimes_content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.howManyTimes_logo{
  margin: auto;
}
.howManyTimes_title_box{
  text-align: center;
}
.howManyTimes_title_vert{
  color: var(--lightGreenFlit);
  margin: 0;
  font-size: 30px !important;


}
.howManyTimes_title_blue{
  color: var(--blueFlit);
  margin: 0;
  font-weight: 700;
  font-size: 30px !important;

}
.howManyTimes_disponibility_content{
  width: 100%;
  margin-bottom: 30px;
}
.howManyTimes_text {
  text-align: center;
  color: var(--blueFlit);
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
}
.howManyTimes_subtitle{
  text-align: center;
  width: 75%;
  margin: 20px auto 20px auto;
}

.howManyTimes_globalButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.howManyTimes_item {
  width: 120px;
  border-radius: 20px;
  font-weight: bold;
  height: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  --background:#F5F5F5
}
.howManyTimes_input {
  text-align: center;
  background-color: white;
  border: none;
  border-radius: 30px;
  width: 50%;
  height: 30px;
  font-weight: 500;
}
.howManyTimes_button {
  background: var(--lightGreenFlit);
  margin-left: 10px;
  margin-right: 10px;
  height: 38px;
  border-radius: 50px;
  width: 38px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.howManyTimes_subtitle_last{
  text-align: center;
  margin-top: 20px;
}


@media screen and (min-width: 400px) {


  .howManyTimes_item{
    width: 160px;
    height: 45px;

  }
}
@media screen and (min-width: 390px) {
  .howManyTimes_disponibility_content{
    width: 95%;
  }
  .howManyTimes_title_vert{
    font-size: 40px !important;
  }
  .howManyTimes_title_blue{
    font-size: 40px !important;
  }

}
@media screen and (min-width:1024px) {
  .howManyTimes_text {
    font-size: 1rem;
  }
  .howManyTimes{
    width: 50%;
    margin: auto;
  }
  .  .howManyTimes_logo{
    width: 300px;
  }
  .howManyTimes_text{
    font-size: 1.3rem;
  }
  .howManyTimes_subtitle{
    font-size: 16px;
  }
  .howManyTimes_button{
    font-size: 1rem;
    width: 40Px;
    height: 40Px;
  }
  .howManyTimes_item{
    width: 120px;
    height: 40px;
    font-size: 20px;
  }

}