.profil {
    margin-bottom: 60px;

}

.profil_header {
    background: linear-gradient(
            to top,
            #ffffff 0%,
            #ffffff 45%,
            #DFF0DF 45%,
            #DFF0DF 100%
    );
    height: 45%;
    width: 100%;
    z-index: 1;
    padding-left: 15px;
    padding-right: 15px;
}


.profil_title_icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    padding-top: 44px;
}

.profil_header_icon {
    font-size: 25px;
    color: var(--blueFlit);
}

.profil_title_icon_text {
    padding-left: 10px;
}

.profil_header_logo_name {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.profil_header_logo_name h3 {
    text-transform: capitalize;

}

.profil_logo {
    width: 125px;
}

.profil_content_list_item {
    margin-bottom: 20px;
}
.profil_content_list_item_support{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
}
.profil_content_list_item_support a{
    padding-left: 5px;
}

.profil_content {
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 20px;
    margin-bottom: 60px;
}

.profil_content_box {
    display: flex;
    border-radius: 30px;
    padding-left: 15px;
    align-items: center;
    justify-content: space-around;
    box-shadow: 1px 5px 8px rgb(51 51 51 / 42%);
    padding-bottom: 5px;
    padding-top: 5px;

}

.profil_content_icon {
    font-size: 20px;
    width: 30px;
    height: 30px;
}

.profil_content_list_objectif {
    margin-bottom: 5px;
}

.profil_content_text {
    font-weight: 700;
    margin-block: 0;
    padding-top: 5px;
}

.profil_content_text_goal {
    margin-block: 0;

}

.profilModal {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profil_content_text_box {
    width: 70%;
}

.profil_modal_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.profil_modal_text {
    background-color: var(--beige);
    border: none;
    text-align: center;
    border-radius: 30px;
    font-weight: bold;
    height: 30px;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

}

.profil_modal_button {
    --background: linear-gradient(
            180deg,
            rgba(0, 116, 115, 1) 0%,
            rgba(0, 116, 115, 1) 35%,
            rgba(101, 183, 111, 1) 100%
    );
    width: 50%;
    --background-activated: none;
    --border-radius: 30px;
    font-size: 16px;
    height: 30px;
}

.profil_content_addObj {
    margin-top: 5px;
    margin-bottom: 10px;
}

.profil_react_icons {
    color: var(--lightGreenFlit);
}

/* PAIEMENT BUTTON*/

.btn_buy_x_day {
    background: linear-gradient(
            180deg,
            rgba(0, 116, 115, 1) 0%,
            rgba(0, 116, 115, 1) 35%,
            rgba(101, 183, 111, 1) 100%
    );
    color: white;
    border-radius: 10px;
    height: 35px;
    margin-top: 5px;
}

.profil_test_button {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

@media screen and ( min-width: 390px) {

    .profil_content_icon {
        width: 40px;
        height: 40px;
    }

    .profil_header_icon {
        font-size: 35px;
    }

    .profil_content_list_item {
        margin-bottom: 30px;
    }

}

@media screen and ( min-width: 410px) {

    .profil_content_icon {
        width: 50px;
        height: 50px;
    }


}