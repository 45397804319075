.formSuccess{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    color: var(--blueFlit);
    height: 100%;
    justify-content: center;

}

.formSuccess_text{

    display: flex;
    justify-content: space-between;
    width: 50%;
    align-items: center;
}

.formSuccess_none{
    display:none;
}
.formSuccess_subTitle{
    text-align: center;
    margin-top: 24px;

}
.formSuccess_spinner{
    transform: scale(1.5);
}
   
.formSuccess_textContent{
   text-align: center;
   width: 90%;
   margin-top: 30px;
    height: 100px ;

}

@media screen and (min-width: 320px) {

    .formSuccess_textContent{
        margin-top: 60Px;
    }

}