.noFractionne_duration{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
}
.fractionne_basse_right{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
.fractionne_basse_list_item{
    height: 100%;
}

.trainingDay_data_box_duration_content_ionText_notsession{
    display: flex;
    align-items: center;
}
.noFractionné_box{
    display: flex;
    justify-content: space-between;
}
.box-test{
    display: flex;
    align-items: center;
}
.noFractionne_list_webhook{
    height: 100%;
    display: flex;
    align-items: center;
}
.noFractionne_box_right{
    display: flex;
    justify-content: space-around;
    padding-right: 10px;
}