.subscribe{
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.susbcribe_box{
    background-color: #F5F5F5;
    color: var(--blueFlit);
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 55vh;
    border-radius: 10px;
}
.susbcribe_box_title{
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}
.susbcribe_box_title_subtitle{
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}
.subscribe_header_text{
    font-size: 13px;
}
.subscribe_box_list{
    padding-left: 10px;
}
.subscribe_box_list_items{
    padding-left: 10px;
    list-style: inside;
}
.subscribe_subtitle{
    font-weight: 500;
    padding-left: 10px;
}
.subscribe_button{
    --background: var(--greenFlit);
    font-weight: 500;
    width: 90%;
    margin-left: 11px;
}

@media screen and (min-width: 1024px) {
    .subscribe{
        margin: auto;
        width: 50%;
    }
    .subscribe_button{
        margin-left: 20px;
    }
}