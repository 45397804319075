.trainingDay {
    color: var(--blueFlit);
    margin-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;


}

.trainingDay_Day {
    display: flex;
    align-items: center;
    justify-content: center;
}
.trainingDay_info_icon{
    color: var(--blueFlit);
    font-size: 25px;
    padding-left: 10px;
    padding-top: 10px;
}
.trainingDay_title_star_content {
    display: flex;
    align-items: center;
    width: 70%;
    justify-content: space-around;
}

/* DATA DENIVELE ECT*/

.trainingDay_data_value_list{
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;

}
.trainingDay_data_value_list_item{
    width: 30%;
}
.trainingDay_data_value_list_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid var(--grey);;
    padding-right: 5px;
}
.trainingDay_data_value_list_box_title{
    color: var(--grey);
    font-weight: bold;
}
.trainingDay_data_value_list_box_data{
    color: var(--blueFlit);
    font-weight: bold;

}
.trainingDay_data_value_list_box p{
    margin: 0;

}
.trainingDay_data_value_list_box_right p{
    margin: 0;
    text-align: center;

}
.trainingDay_data_value_list_box_right{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5px;
}
.day {
    font-weight: bold;
    margin: 0;
    padding-right: 10px;
    padding-top: 4px;

}

.pourcentage {
    padding-left: 5px;
    padding-top: 3px;
}

.trainingDay_subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.trainingDay_title_content {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-top: 30px;
}

.trainingDay_icon_content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.trainingDayPlanBox_session_content_boxIcon {
    background-color: var(--greenFlit);
    width: 35px;
    border-radius: 30px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.trainingDay_icon_content {
    display: flex;
    flex-direction: column;
}

.trainingDayPlanBox_session_content_icon {
    color: white;
}

.trainingDay_typeof {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--greenFlit);
    font-weight: bold;
}

.trainingDay_totalTime_content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.trainingDay_totalTime {
    color: var(--lightGreenFlit);
    border-left: 1px solid var(--blueFlit);
    padding-left: 5px;
}
.trainingDay_totalTimeNo{

    color: var(--lightGreenFlit);


}
.trainingDay_goalsTotalTime{
    color: var(--blueFlit);
    padding-right: 5px;

}

.trainingDay_totalTime_icon {
    color: var(--blueFlit);
    margin-right: 10px;
    margin-top: 5px;
}

.trainingDay_button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.trainingDay_button_chart {
    width: 100px;
    height: 40px;
    background: var(--blueFlit);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: bold;
}

.trainingDay_button_list {
    width: 100px;
    height: 40px;
    background: var(--blueFlit);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-right: 2px;
    font-weight: bold;
    color: white;
}

.active {
    background: var(--greenFlit);
}

.trainingDay_step {
    margin: auto;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    width: 90%;
    font-weight: 500;
    color: #89969F;
}

.trainingDay_globalList {
    display: flex;
}

.span_test {
    width: 3px;
    background-color: #e8ebed;
    left: 6px;
    height: 95%;
    border-radius: 10px;
    margin-left: 10px;
    margin-top: 35px;

}

.trainingDay_content_box {
    width: 100%;
}

.trainingDay_boxTitle {
    display: flex;
    align-items: center;
    padding-left: 5px;
    justify-content: space-between;
}
.trainingDay_chevron{
    padding-right: 15px;
    font-size: 18px;
    color: var(--greenFlit);
    padding-top: 10px;
    padding-bottom: 10px;

}
.trainingDay_label_point_training {
    width: 10px;
    height: 10px;
    background: var(--greenFlit);
    border-radius: 30px;
    position: absolute;
    left: 17px;


}
.trainingDay_data_box_duration_allure{
    padding-right: 15px;
    color: var(--grey);
    font-weight:bold

}

.trainingDay_data_box_duration_title {
    font-weight: bold;
    color: var(--greenFlit);

}

.trainingDay_data_box_duration_title p {
    margin-block: 5px !important;
}

.trainingDay_data_box_duration_list {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;


}

.trainingDay_data_box_duration {
    margin: auto;
    width: 95%;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    margin-bottom: 10px;
    margin-top: 10px;

    padding-bottom: 10px;

}

.trainingDay_box{
    margin: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    background-color: var(--beige);
    border-radius: 20px;
    height: 100%;

}


.trainingDay_data_box_duration_white {
    width: 95%;
    margin: auto;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 5px;
    height: 100%;
    padding-bottom: 10px;
}

.trainingDay_data_box_duration_content_duration {
    font-weight: bold;

}

.trainingDay_data_box_duration_list_item {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    flex-direction: column;
}
.fractionne_basse{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.trainingDay_data_box_duration_content {
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;
    padding-right: 5px;
    border-radius: 5px;
    align-items: center;

}

.trainingDay_data_box_duration_content_repeat {
    display: flex;
    justify-content: flex-end;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: var(--beige);
}

.trainingDay_test {
    display: flex;
    justify-content: space-around;
    background-color: white;
    box-shadow: 1px 5px 8px rgb(51, 51, 51, 0.418);
    margin-bottom: 10px;
    border-radius: 20px;
    margin-right: 10px;
    margin-left: 10px;
}
.trainingDay_test_nowhite{
    display: flex;
    justify-content: space-around;


}

.trainingDay_duration_list_nowhite{
    display: flex;
    justify-content: center;
    align-items: center;

}

.trainingDay_duration_list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    flex-direction: column;

;


}

.trainingDay_data_box_goal {
    display: flex;

    height: 100%;
    justify-content: space-around;
    align-items: center;
}

.trainingDay_data_box_duration_content_ionText_repeat {
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    color: var(--blueFlit);
    margin: 0;
    min-height: 40px;


}



.trainingDay_data_box_duration_content_repeat {
    font-weight: bold;
    font-size: 18px;
}

.trainingDay_data_box_duration_label_list {
    display: flex;
    justify-content: space-around;
    text-align: center;
    border-radius: 5px;
    flex-direction: column;

}
.trainingDay_data_box_duration_label_text{
    padding-left: 5px;
    display: flex;
    align-items: center;
    color: var(--grey);
    font-weight: 500;

}

.trainingDay_data_box_duration_label_list li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}


.training_data_box_duration_done {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    flex-direction: column;

    border-radius: 5px;
    width: 35%;
}

.training_data_box_duration_done li {
    width: 100%;
}

.training_data_box_duration_color_notsuccess {
    background-color: orange;
    width: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-left: 5px;
}

.training_data_box_duration_color_success {
    background-color: #6fc379;
    width: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-left: 5px;
}

.training_data_box_duration_color_text_notsuccess {
    color: red;
    padding-left: 10px;
}

.training_data_box_duration_color_text_success {
    color: var(--lightGreenFlit);
    padding-left: 10px;
}

.trainingDay_data_box_duration_goal_list_item {
    display: flex;
    height: 100%;
    width: 100%;
    padding-left: 15px;

}

.training_data_box_goal_color_text_success {
    color: var(--lightGreenFlit);
    padding-left: 10px;
}

.training_data_box_goal_color_text_notsuccess {
    color: red;

}

.training_data_box_duration_done_text {

    text-align: center;
}

.training_data_box_duration_done_text_standby {
    text-align: center;
}


.trainingDay_segmentTime {
    display: flex;
    height: 100%;
    flex-direction: column;
}
.trainingDay_segmentAllure{
    display: flex;
}
.trainingDay_webhook_allure{
    display: flex;
    justify-content:space-around ;
    flex-direction: column;

}
.trainingDay_webhook_list{
    display: flex;
    height: 100%;
}
.trainingDay_oneData{
    display: flex;
    align-items: center;
    height: 100%;
}
.testla {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
}
.trainingDay_recovery_avg_pace_intensity p{
    padding-bottom: 5px;

}
.trainingDay_label_content{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.trainingDay_recovery_intensity {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: inherit;
    padding-left: 5px;
}


.trainingDay_recovery_intensity_success {

    color: var(--lightGreenFlit);
    padding-left: 5px;

}

.trainingDay_recovery_intensity_notsuccess {
    color: red;
    padding-left: 5px;
}

.trainingDay_su {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
}

.trainingDay_data_done_recovery {
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.trainingDay_data_global_box {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
}

.trainingDay_allure_test {
    display: flex;
}
.trainingDay_recovery_intensity_img{
    width: 25px;
    height: 25px;
}
.repeat_none{
    display: none;
}

.trainingDay_fabButton{
    --background: linear-gradient(
            180deg,
            rgba(0, 116, 115, 1) 0%,
            rgba(0, 116, 115, 1) 35%,
            rgba(101, 183, 111, 1) 100%
    );

}

@media screen and (min-width: 390px) {
    .trainingDay_button_list {
        width: 120px;
        height: 50px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }

    .trainingDay_button_chart {
        width: 120px;
        height: 50px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
    }
    .trainingDayPlanBox_session_content_boxIcon{
        width: 45px;
        height: 45px;
    }
}

@media screen and (min-width: 1024px) {
    .trainingDay {
        width: 50%;
        margin: auto;
    }

    .trainingDay_label_point_training {
        display: none;
    }
}
@media screen and (min-width: 320px) {
    .trainingDay_recovery_intensity_img{
        width: 20px;
        height: 20px;
    }

}