.weekOfRaces_content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }
  .weekOfRaces_logo{
    width: 200px;
    margin: auto;
  }
  .weekOfRaces_text {
    text-align: center;
    color: var(--blueFlit);
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;

  }
  .weekOfRaces_subtitle{
    text-align: center;
  }
  .weekOfRaces_globalButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .weekOfRaces_item {
    width: 120px;
    border-radius: 20px;
    font-weight: bold;
    height: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    --background:#F5F5F5
  }
  .weekOfRaces_input {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    --padding-start: 15px !important;
      margin-right: 10px;
  }
  .weekOfRaces_button {
    --background: var(--lightGreenFlit);
    margin-left: 10px;
    margin-right: 10px;
    height: 37px;
    --border-radius: 50px;
    width: 38px;
  }
  
  @media screen and (min-width:1024px) {
    .weekOfRaces_text{
      font-size: 1.7rem;
    }
  }