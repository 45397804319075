.newDispo_content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin-top: 80px;
}

.newDispo_text {
    text-align: center;
    color: var(--blueFlit);
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
}
.newDispo_subtitle{
    text-align: center;
}
.newDispo_globalButton {
    display: flex;
    justify-content: center;
    align-items: center;
}
.newDispo_item {
    width: 120px;
    border-radius: 20px;
    font-weight: bold;
    height: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    --background:#F5F5F5
}
.newDispo_input {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    --padding-start: 15px !important;
    margin-right: 10px;
}
.newDispo_button {
    --background: var(--lightGreenFlit);
    margin-left: 10px;
    margin-right: 10px;
    height: 37px;
    --border-radius: 50px;
    width: 38px;
}


@media screen and (min-width: 400px) {

    .newDispo_button{

        width: 60Px;
        height: 60Px;
    }
    .newDispo_item{
        width: 160px;
        height: 45px;

    }
}
@media screen and (min-width:1024px) {
    .newDispo_text {
        font-size: 1rem;
    }
    .newDispo{
        width: 50%;
        margin: auto;
    }
    .  .newDispo_logo{
        width: 300px;
    }
    .newDispo_text{
        font-size: 1.3rem;
    }
    .newDispo_subtitle{
        font-size: 16px;
    }
    .newDispo_button{
        font-size: 1rem;
        width: 40Px;
        height: 40Px;
    }
    .newDispo_item{
        width: 120px;
        height: 40px;
        font-size: 20px;
    }

}