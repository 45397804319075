.weekBilan{
  padding-left: 15px;
  padding-right: 15px;
}
.weekBilan_title_subtitle {
  text-align: center;
  margin-bottom: 40px;
}
.weekBilan_content_list {
  width: 90%;
  margin: auto;
  margin-bottom: 50px;
}
.weekBilan_content_list_item {
  box-shadow: 0px 0px 15px -9px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  margin-bottom: 10px;
}
.weekBilan_content_list_icon {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.weekBilan_content_list_text_title {
  text-align: center;
}
.weekBilan_content_list_text_title h3{
  margin-bottom: 0;
}

.weekBilan_content_list_text {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 80%;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.weekBilan_content_list_text_subtitle {
  text-align: center;
}

.weekBilan_content_title_box{
  margin-block: 0;
  margin-bottom: 5px;
  margin-top: 10px;
}
.weekBilan_content_text_box{
  font-size: 15px;
  margin-block: 0;
  margin-bottom: 10px;
  color: var(--blueFlit);
}
.weekBilan_content_list_text_icon_one {
  color: red;
  font-size: 25px;
}
.weekBilan_content_list_text_icon_two {
  color: orange;
  font-size: 25px;
}
.weekBilan_content_list_text_icon_three {
  color: greenyellow;
  font-size: 25px;
}
.weekBilan_content_list_text_icon_four {
  color: blue;
  font-size: 25px;
}
.weekBilan_content_list_text_icon_five {
  color: violet;
  font-size: 25px;
}
.weekBilan_content_list_text_subtitle {
  font-size: 12px;
}
.weekBilan_content_list_item_subList {
  list-style: inside;
}
.weekBilan_actived {
  background: var(--greenFlit);
  color: white;
  box-shadow: 0px 0px 15px -9px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  margin-bottom: 10px;
}
@media screen and (min-width: 1024px) {
  .weekBilan {
    width: 60%;
    margin: auto;
  }
}
