.objectif{
    --background: var(--beige);
}
.objectif_logo {
    margin-top: 15px;
}

.objectif_title_box{
    margin: auto;
}
.objectif_title_box_part1{
    color: var(--lightGreenFlit);
    font-size: 40px;
    font-weight: 900;
    margin: 0;
    text-align: center;
}
.objectif_title_box_part2{
    color: var(--blueFlit);
    font-weight: 900;
    margin: 0;
    text-align: center;

}
.objectif_subtitle{
    text-align: center;
}
.objectif_list{
    margin: auto;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.objectif_list_item{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.objectif_list_item_button{
    width: 80%;
    border: 0.5px solid var(--lightGreenFlit);
    background: none;
    border-radius: 30px;
    height: 30px;
    font-weight: bold;
    color: var(--blueFlit);
}
.type_active{
    background-color: var(--lightGreenFlit);
    color: white;
}
.objectif_modal{
    --width:90%;
    --height:350px;
    color: var(--blueFlit);

}
.objectif_time_list_item{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.objectif_icon_button_box{
    background: var(--greenNew);
    width: 30px;
    height: 30px;
    font-size: 20px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.objectif_icon_button{
    color: var(--lightGreenFlit);
    font-size: 20px;
}
.objectif_modal_button{
    --background: var(--greenNew);
    --border-radius: 30px;
    --color: var(--blueFlit);
    height: 30px;
    width: 40%;
    --font-size: 12px;
    box-shadow: 1px 5px 8px rgb(51 51 51 / 42%);
    border-radius: 30px;

}

@media screen and (min-width: 390px){
    .objectif_list_item_button{
        width: 70%;
    }
}