.updatePassword{
    height: 200px;
}
.updatePassword_form {

    box-shadow: 0 1px 10px -4px;
    width: 90%;
    margin: 10px auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 20px;
    height: 100%;
}

.updatePassword_input {
    background-color: white;
    box-shadow: 0 1px 10px -4px;
    border-radius: 30px;
    border: none;
    padding-left: 20px;
    height: 30px;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.updatePassword_input::placeholder{
    font-size: 11px;
    color: var(--grey);
}
.updatePassword_content {
    display: flex;
    align-items: center;
}
.register_invalidPassword{
    text-align: center;
    color: red;
    margin: 0;
}
.updatePassword_input_icon {
    font-size: 20px;
    margin-left: 5px;

}

.updatePassword_button {
    background: linear-gradient(
            90deg,
            rgba(0, 116, 115, 1) 0%,
            rgba(0, 116, 115, 1) 35%,
            rgba(101, 183, 111, 1) 100%
    );
    color: white;
    border:none;
    width: 50%;
    border-radius: 30px;
    font-size: 14px;
    font-weight: bold;
    text-transform: none;
    height: 25px;
}
