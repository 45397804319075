.vmaLenght_echauffement_calm_list{
    display: flex;
    width: 95%;
    margin: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    background-color: var(--beige);
    border-radius: 20px;
    height: 100%;
}
.vmaLenght_echauffement_calm_list_box_left {
    display: flex;
    width: 40%;
    justify-content: flex-start;
    padding-left: 10px;
    align-items: center;
}
.vmaLenght_echauffement_calm_list_nowebhook{
    font-weight: 700;
}
.vmaLenght_echauffement_calm_list__allure_nowebhook{
    color: var(--grey);
}
.vmaLenght_echauffement_calm_list_box_right {
    display: flex;
    width: 60%;
    justify-content: flex-end;
    padding-right: 10px;
    align-items: center;
}
.vmaLength_session_box{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    background-color: var(--beige);
    border-radius: 20px;
}
.vmaLength_session_box_left_list{
    display: flex;
    flex-direction: column;
}
.vmaLength_session_box_content{
    display: flex;
    width: 95%;
    justify-content: space-between;
    background: white;
    border-radius: 20px;
    box-shadow: 1px 5px 8px rgb(51 51 51 / 42%);
    margin: auto;
    padding-left: 9px;
    padding-right: 10px;
}
.vmaLength_session_box_content_noDetails{
    display: flex;
    width: 95%;
    justify-content: space-around;
    margin: auto;
}
.vmaLength_session_box_left{
    display: flex;
    padding-left: 5px;



}
.vmaLength_box_left_nowebhook{
    font-weight: 700;
}
.vmaLength_session_box_right{
    display: flex;
}
.vmaLength_box_right_nowebhook{
    color: var(--grey);
    font-weight: 500;
}
.ok{
    border-bottom: 1px solid black;
}
.vmaLength_session_box_time_webhook{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 5px;
    align-items: flex-start;
}

.vmaLength_box_right_content_flitou{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.vmaLength_time_webhook_success_echauffement{
    color: var(--lightGreenFlit);
    padding-right: 5px;
    padding-left: 5px;

}
.vmaLength_time_webhook_nosuccess_echauffement{
    color: red;
    padding-right: 5px;
    padding-left: 5px;

}
.vmaLength_time_webhook_success{
    color: var(--lightGreenFlit);

}
.vmaLength_time_webhook_nosuccess {
    color: red;

}
.box_global{
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;

}
.box_repeat{
    display: flex;
    font-weight: bold;
    text-align: center;
    color: var(--blueFlit);
    margin: 0;
    padding-left: 10px;
    padding-top: 5px;
}
.box_right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
}
.box_left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
}
.box_right p{
    padding-right: 5px;
}
.box_right_label{
    color: var(--grey);
}
.box_content{
    background: white;
    border-radius: 20px;
    box-shadow: 1px 5px 8px rgb(51 51 51 / 42%);
    margin-bottom: 10px;
}
.box_title{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.vmaLength_session_box_noDetails{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.noscroll{
    padding-left: 5px;
}