.tab1_header {
    --background: linear-gradient(
            90deg,
            rgba(0, 116, 115, 1) 0%,
            rgba(0, 116, 115, 1) 35%,
            rgba(101, 183, 111, 1) 100%
    );
    display: flex;
    min-height: 54px;
}
.tab1_bar_content_list{
    width: 75%;
    display: flex;
    flex-direction: column;

}
.tab1_bar_content_pourcent_text{
    font-size: 9px;
    background-color: var(--blueFlit);
    border-radius: 20px;
    color: white;
    width: 25px;
    text-align: center;

}
.tab1_bar_content_pourcent_box{
    display: flex;
    justify-content: flex-end;


}
.tab1_bar_content_pourcent{
    width: 100%;
    height:15px ;
    background: transparent;
}
.tab1_bar_content {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 30px;
    width: 100%;
    height: 20px;


}
.tab1_content{
    display: flex;
    align-items: center;

    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;

}
.tab1_logo{
    width: 20px;
    height: 30px;

    margin-top: 10px;

}
.tab1_progressBar{
    height: 100%;
    border-radius: 30px;
    text-align: center;
    color: white;
    background-color: #B5DBB8;
    display: flex;
    justify-content: flex-end;
}
.tab1_bar_content_text{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.tab1_progressBar_round{
    width: 20px;
    height: 20px;
    border-radius: 30px;
    background: white;
    border: #B5DBB8 1px solid;

}
.tab1_progressBar_round_empty{
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 30px;
    border:#B5DBB8 1px solid;
}
.tab1_progressBar_objectiv{
    margin-top: 20px;
}
.tab1_progressBar_objectiv p{
    font-size: 9px;
    margin-block: 0;
    color: white;

}
.tab1_progressBar_objectiv_icon{
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 30px;
}
.tab1_progressBar_objectiv_icon_box{
    display: flex;
    align-items: center;
    background-color: var(--blueFlit);
    border-radius: 30px;
    justify-content: space-between;
    padding-right: 3px;
    height: 15px;

}