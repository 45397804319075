.star{
    display: flex;
}
.star_img{
    width: 20px;
    height: 20px;
}

@media screen and (min-width: 320px){
    .star_img{
        width: 15px;
        height: 15px;
    }
}