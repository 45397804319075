.pedagogie{

    margin-bottom: 40px;
}
.pedagogie_img{
    width: 150px;
    margin-top: 44px;
}
.pedagogie_title_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
            to top,
            #ffffff 0%,
            #ffffff 45%,
            #DFF0DF 45%,
            #DFF0DF 100%
    );
    height: 45%;
    width: 100%;
    z-index: 1;
    padding-left: 15px;
    padding-right: 15px;
}
.pedagogie_title_box {
    display: flex;
    flex-direction: column;
    margin-block: 0;
    text-align: center;
}
.pedagogie_title_box h2 {
    margin-block: 0;
}
.pedagogie_title_box_second{
    display: flex;

}
.pedagogie_title_conseil{
    color: var(--lightGreenFlit);
    padding-right: 5px;
}
.pedagogie_seance{
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.pedagogie_seance_title{
    padding-left: 10px;
    margin-top: 40px;
    color: var(--greenFlit);
    font-weight: bold;

}
.pedagogie_seance_title_training{
    padding-left: 10px;
    margin-top: 40px;
    color: var(--lightGreenFlit);
    font-weight: bold;
}
.pedagogie_seance_list{
    padding-left: 15px;
    padding-right: 15px;
}
.pedagogie_seance_list_item{
    box-shadow: 1px 5px 8px rgb(51 51 51 / 42%);
    border-radius: 30px;
    width: 100%;
    padding: 5px;
    margin-bottom: 25px;
    padding-left: 10px;
    display: flex;
    align-items: center;

}
.pedagogie_seance_list_item p {
    color: var(--greenFlit);
    margin: 5px 0 5px 10px;
}
.pedagogie_icon_training{
    background-color: var(--lightGreenFlit);
    border-radius: 30px;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.pedagogie_text_training{
    color: var(--lightGreenFlit) !important;
}
.pedagogie_back_button{
    font-size: 30px;
}
.pedagogie_content_icon p {
    padding-left: 10px;
}
.pedagogie_content_icon{
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.pedagogie_icon{
    background-color: var(--greenFlit);
    border-radius: 30px;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 390px) {
    .pedagogie_seance_list_item{
        margin-bottom: 30px;
    }
}