.connexion_global{
    --background: var(--greenNew);
}
.connexion{
    padding-right: 15px;
    padding-left: 15px;
}

.connexion_title_bienvenue{
    color: var(--lightGreenFlit);
    text-align: center;
    font-weight: 900;
    margin: 0;
}
.connexion_title_flit{
    color: var(--blueFlit);
    text-align: center;
    font-weight: bold;
    margin: 0;
}
.connexion_passwordValid{
    color: red;
    width: 58%;
    text-align: center;
    margin: auto;
}
.connexion_list{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 90px;
    margin: 10px auto;
    width: 100%;
}
.connexion_list_item{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.connexion_list_input{
    width: 80%;
    background-color: white;
    border: none;
    border-radius: 30px;
    padding-left: 20px;
    height: 30px;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.connexion_list_input_icon{
    font-size: 20px;
    padding-right: 10px;
}
.connexion_list_input_password{
    background:none;
    outline: none;
    border: none;
    width: 90%;
}
.connexion_toregister{
    color: var(--greenFlit);
    text-decoration: underline;
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
}
@media screen and (min-width: 390px) {
    .connexion_list{
        height: 120px;
    }
    .connexion_list_input{
        height: 40px;
    }
    .connexion_toregister{
        margin-top: 10px;
    }

}