.noviceLevel_question{
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;

}
.noviceLevel_question_content{
    height: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.noviceLevel_title_box{
    margin-bottom: 30px;
}
.noviceLevel_title_vert{
    color: var(--lightGreenFlit);
    margin: 0;
    font-weight: 900;
    margin-top: 10px;
}
.noviceLevel_title_blue{
    color: var(--blueFlit);
    margin: 0;
    font-weight: 900;
    text-align: center;
}
.noviceLevel_question_title{
    text-align: center;
    color: var(--blueFlit);
    margin-block: 0;
}
.noviceLevel_input{
    background-color: white;
    border: none;
    text-align: center;
    border-radius: 30px;
    font-weight: 500;
    height: 30px;
    width: 50%;
    font-size: 14px;
    outline: none;
}
.noviceLevel_question_Content{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 10px;

}
.noviceLevel_question_modal{
    width: 50%;
    --background: linear-gradient(
            180deg,
            rgba(0, 116, 115, 1) 0%,
            rgba(0, 116, 115, 1) 35%,
            rgba(101, 183, 111, 1) 100%
    );
    --background-activated: none;
    --border-radius: 30px;
    font-size: 16px;
}