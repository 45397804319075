.weekbilandisponibility_text{
    text-align: center;
    color: var(--blueFlit);
    padding-left: 10px;
    padding-right: 10px;
}
.weekBilandisponibility_title{
    padding-right: 15px;
    padding-left: 15px;
    color: var(--blueFlit);
}
.weekBilanDisponibility_button{
    --background: linear-gradient(
            180deg,
            rgba(0, 116, 115, 1) 0%,
            rgba(0, 116, 115, 1) 35%,
            rgba(101, 183, 111, 1) 100%
    );
    width:80%;
    --background-activated: none;
    --border-radius: 30px;
    font-size: 16px;
}
.weekBilan_list{
    width: 90%;
}
.weekBilanDisponibility_modal{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 90%;
}

@media screen and (min-width :1024px) {
    .weekBilanDisponibility_modal{
        width: 70%;
        margin: auto;
    }
}