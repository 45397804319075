
.pedaDetails{
    margin-bottom: 40px;
}
.pedaDetails_title_content_phase{
    background: linear-gradient(
            to top,
            #ffffff 0%,
            #ffffff 40%,
            rgba(101, 183, 111, 0.3)40%,
            rgba(101, 183, 111, 0.3)100%
    );
    height: 45%;
    width: 100%;
    z-index: 1;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.pedaDetails_backIcon_content{
    padding-top: 44px;
}
.pedaDetails_title_content_type{
    background: linear-gradient(
            to top,
            #ffffff 0%,
            #ffffff 35%,
            rgba(0, 116, 115, 0.3)35%,
            rgba(0, 116, 115, 0.3)100%
    );
    height: 45%;
    width: 100%;
    z-index: 1;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.pedaDetails_backIcon{
    font-size: 30px;
    padding-top: 10px;
}
.pedaDetails_title_content_title{
    margin: 0;
    text-align: center;
    padding-bottom: 20px;
    font-weight: bold;
}
.pedaDetails_info_content{
    width: 90%;
    margin:auto;
}
.pedaDetails_icon_phase{
    background: var(--lightGreenFlit);
    width: 80px;
    height: 80px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    padding: 10px;
    color: white;

}
.pedaDetails_icon_type{
    background: var(--greenFlit);
    width: 80px;
    height: 80px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    padding: 10px;
    color: white;


}
.pedaDetails_info_content_title_text{
    margin-bottom: 20px;
}
.pedaDetails_info_content_list{
    margin-top: 20px;
}
.pedaDetails_info_content_list_box{
    background-color: var(--beige);
    border-radius: 20px;
    padding: 10px;
}

.pedaDetails_info_content_title{
    text-align: center;
    font-weight: bold;
}

@media screen and (min-width: 390px) {
    .pedaDetails_icon_phase{
        margin: 20px auto 0 auto;
        width: 100px;
        height: 100px;
    }
    .pedaDetails_icon_type{
        margin: 20px auto 0 auto;
        width: 100px;
        height: 100px;
    }
    .pedaDetails_title_content_type{
        height: 35%;

    }
    .pedaDetails_title_content_phase{
        height: 35%;

    }
    .pedaDetails_info_content{
        width: 80%;
    }

}