.settings_account{
    margin-top: 30px;
    color: var(--blueFlit);
    font-weight: 500;
}
.settings_about{
    margin-top: 30px;
    color: var(--blueFlit);
}
.settings_account_list {
  width: 90%;
  margin: auto;
}
.settings_account_list_items {
    box-shadow: 0px 1px 10px -4px;
    width: 90%;
    margin:auto;
    margin-top: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}
.settings_title{
    padding-left: 25px;
    font-size: 17px;
    font-weight: bold;
    
}
.disabled_items{
  background-color: #f5f5f5;
  color: grey ;
  border-color: grey;
}
@media screen and (min-width :1024px) {
  .settings_account{
    width: 50%;
    margin: auto;
  }
  .settings_about{
    width: 50%;
    margin: auto;
  }
}