.connect{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.connect_logo{

    margin: 15px auto;
}
.connect_title_vert{
    text-align: center;
    color: var(--lightGreenFlit);
    font-weight: 900;
    margin: 0;
    font-size: 40px;
}
.connect_modal_noAccount .alert-message{
    color: var(--blueFlit);
    text-align: center;
    font-weight: 500;
}
.connect_title_blue{
    text-align: center;
    color: var(--blueFlit);
    font-weight: 900;
    margin: 0;
}

.connect_global_title{
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: center;
    color: var(--blueFlit);
}
.connect_content{
    display: flex;
    flex-direction: column;
    width: 90%;
}
.connect_content_button{
    width: 60%;
}
.connect_button_list{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100px;
}
.connect_button_list_item{
    border-radius: 30px;
    width: 80%;
    border: 0.5px solid var(--lightGreenFlit);
    display: flex;
    justify-content: center;
    align-items: center;
}
.connect_button_list_item_noAccount{
    border-radius: 30px;
    width: 80%;
    border: 0.5px solid var(--lightGreenFlit);
    display: flex;
    justify-content: center;
    align-items: center;
}
.connect_button_list_text_noAccount{

    padding-left: 10px;
    font-weight: bold;
}
.connect_button_list_img{
    width: 20px;
    height: 20px;
}
.connect_button_list_text{
    width: 70%;
    padding-left: 10px;
    font-weight: bold;
}
.connect_active{
    --background: var(--greenFlit);
    --border-radius: 5px;

}
.connect_content_button_strava{
    display:flex;
    align-items: center;

}
.connect_content_button_strava_icon{
    font-size: 1.5rem;
padding-left: 10px;}

@media screen and (min-width :1024px) {
    .connect{
        width: 50%;
        margin: auto;
    }
    .connect_content{
        width: 50%;
    }
    .connect_title{
        font-size: 1.7rem;
    }
}
@media screen and (min-width: 390px) {
    .connect_button_list{
        height: 130px;
    }
    .connect_button_list_img{
        width: 30px;
        height: 30px;
    }
}
@media screen and (min-width: 410px) {
    .connect_button_list{
        height: 160px;
    }

}