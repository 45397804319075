
.trainingWeekPlan {
    padding-right: 10px;
    padding-left: 10px;
}
.trainingWeekPlan_title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%
}


.trainingWeekPlan_title_text {
    padding-bottom: 5px;
    color: var(--blueFlit);
    font-weight: bold;
    margin: 0 auto;
}

.trainingWeekPlan_cycle {
    color: var(--grey);
    font-weight: initial;
}

.trainingWeekPlan_submesocycle {
    padding-left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.trainingWeek_info_icon{
    color: var(--grey);
    font-size: 25px;
    padding-left: 10px;
    padding-top: 10px;
}

.trainingWeekPlan_list {
    margin-bottom: 60px;

    display: flex;
}

.trainingWeekPlan_bar {
    width: 3px;
    background-color: #e8ebed;
    left: 6px;
    height: 100%;
    border-radius: 10px;
    margin-left: 5px;
    margin-top: 15px;
}

.trainingWeekPlan_allContent {
    width: 100%;
}

.trainingWeekPlanBox {
    background-color: var(--beige);
    color: var(--blueFlit);
    width: 90%;
    border-radius: 10px;
    margin: auto;
    margin-top: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    height: 100px;
}

.trainingWeekPlanBox_day {
    font-weight: bold;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--blueFlit);
    padding-left: 10px;

}

.actived_day {
    color: var(--greenFlit) !important;
}

.trainingWeekPlanBox_session {
    color: var(--blueFlit);
    border-radius: 10px;
    font-size: 15px;
    height: 45px;
    width: 95%;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 4px 5px -4px

}

.trainingWeekPlanBox_session_content {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.trainingWeekPlanBox_session_content p {
    margin: 0
}

.trainingWeekPlanBox_session_content_icon {
    color: white;
    font-size: 22px;
}

.trainingWeekPlanBox_day_repos {
    width: 90%;
    margin: auto;

}

.trainingWeekPlanBox_day_repos_title_content {
    display: flex;
    align-items: center;
    width: 40%;
}

.trainingWeek_label_point_training {
    width: 10px;
    height: 10px;
    background: var(--greenFlit);
    border-radius: 30px;
    position: absolute;
    left: 12px;

}

.bar_none {
    display: none;
}

.trainingPlanBox_day_repos_content {
    display: flex;

}

.trainingWeekPlanBox_day_repos_day {
    font-weight: bold;
    margin: 0;
    color: var(--blueFlit);
    padding-left: 10px;
}

.traininWeekPlan_text_content {
    text-align: center;
    width: 20%;
}

.trainingWeekPlanBox_day_repos_data {
    color: #89969F;
}

.trainingWeekPlanBox_session_content_boxIcon {
    background-color: var(--greenFlit);
    border-radius: 30px;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trainingWeekPlanBox_session_content_box {
    width: 72%;
    font-weight: bold;

}



.trainingWeekPlanBox_session_content_box_unexpected {
    width: 72%;
    font-weight: bold;
}

.trainingWeekPlanBox_session_content_boxTime {
    width: 22%;
    color: var(--blueFlit);

}

.trainingWeekPlanBox_boxIcon {
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 0;
    padding-right: 10px;
}

.trainingWeekPlanBox_icon_success {
    font-size: 25px;
    color: #6FC379;
    position: absolute;
    top: -15px;
    right: -10px;
}

.trainingWeekPlanBox_icon_notsuccess {
    font-size: 25px;
    color: orange;
    position: absolute;
    top: -15px;
    right: -10px;
}

.trainingWeekPlanBox_icon_unexpected {
    font-size: 25px;
    color: #6496ED;
    position: absolute;
    top: -15px;
    right: -10px;
}

.trainingWeekPlan_infoList {
    display: flex;
    justify-content: space-around;
    font-size: 13px;
    margin-top: 30px;

}

.trainingWeekPlan_infoList_item {
    display: flex;
    align-items: center;
}

.trainingWeekPlan_infoList_icon_success {
    color: #6FC379;
    margin-left: 5px;
}

.trainingWeekPlan_infoList_icon_progress {
    color: orange;
    margin-left: 5px;
}

.trainingWeekPlan_infoList_icon_unexpected {
    color: #6496ED;
    margin-left: 5px;
}

.weekBilanButtonShow {
    font-size: 30px;
    color: var(--lightGreenFlit);
    margin-bottom: 5px;
}
.weekBilanButtonShow_notVip{
    color: red;
    font-size: 30px;
    margin-bottom: 5px;
}

.weekBilanButtonShow_text {
    color: white !important;
    font-size: 16px;
}

.weekBilanButtonShow_icon {
    font-size: 20px;
    margin-top: 5px;
    margin-left: 15px
}


/* historique*/

.historique_decrement_none {
    color: lightgrey;
    font-size: 30px;
    background: none;
    padding: 0;
}

.trainingWeekPlan_title_history {
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    width: 95%;
}

.historique_decrement_content {
    width: 15%;
    display: flex;

}

.historique_decrement {
    font-size: 30px;
    background: none;
    color: var(--blueFlit);
    padding: 0;
}

.historique_increment {
    font-size: 30px;
    width: 15%;
    display: flex;
    align-items: center;
    background: none;
    justify-content: flex-end;

}

.historique_double {
    font-size: 30px;
    color: var(--blueFlit);
    background: none;
    padding: 0;

}

.historique_double_none {

    display: none;
}


@media screen and (min-width: 400px) {
    .trainingWeekPlanBox_session_content_boxIcon {
        width: 40px;
        height: 40px;
    }
}

@media screen and (min-width: 1024px) {
    .trainingWeekPlan {
        width: 50%;
        margin: auto;
    }

    .trainingWeek_label_point_training {
        display: none;
    }
}


@keyframes wheelHueColor {
    0% {
        background-color: var(--blueFlit);
    }
    50% {
        background-color: var(--greenFlit);
    }
    100% {
        background-color: var(--blueFlit);
    }
}
@media screen and (min-width: 320px){
    .trainingWeekPlanBox{
        height: 75px;
    }
    .trainingWeekPlanBox_session{
        height: 35px;
    }
    .trainingWeekPlanBox_session_content_boxIcon{
        width: 25px;
        height: 25px;
    }
}
@media screen and (min-width: 390px){
    .trainingWeekPlanBox{
        height: 80px;
    }
}