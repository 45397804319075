.stravaSuccess{
    height: 100Vh;
}
.stravaSuccess_content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}
.stravaSuccess_content_logo{
    font-size: 4rem;
    color: var(--greenFlit);
    margin-bottom: 30px;
}

.stravaSuccess_subTitle{
    font-size: 1.2rem;
}