.objectifInter{
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
}
.objectifInter_title_content{
    text-align: center;
    margin-bottom: 20px;
}
.objectifInter_title_box{
    margin: auto;
}
.objectifInter_title_vert{
    margin: 0;
    color: var(--lightGreenFlit);
    font-weight: 900;
}
.objectifInter_title_bleu{
    margin: 0;
    color: var(--blueFlit);
    font-weight: 900;
}
.objectifInter_subtitle{
    text-align: center;
    width: 80%;
    margin:10px auto;
}
.objectifInter_list_obj{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.objectifInter_list_obj_number{
    font-weight: 700;
}
.objectifInter_list_obj_text_distance{
    background-color: white;
    border-radius: 20px;
    text-align: center;
    padding: 15px;
    width: 40%;
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}
.objectifInter_list_obj_text_date{
    background-color: white;
    border-radius: 20px;
    text-align: center;
    padding: 15px;
    width: 40%;
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
}

.objectifInter_list_obj_icon{
    font-size: 30px;
    color: var(--lightGreenFlit);
 }

.objectifInter_content_choose{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.objectifInter_select{
    background-color: white;
    border-radius: 20px;
    padding: 15px;
    --inner-border-width:none;
    border-style: none;
    width: 40%;
    font-size: 11px;
    color: var(--blueFlit);
    --placeholder-opacity: 1 !important;
    font-family: Nunito, sans-serif;
    text-align: center;
}
#ion-sel-8-lbl{
    border-style: none !important;
}
.objectifInter_modal_button{
    --background: white;
    color: var(--blueFlit);
    --border-radius: 20px;
    width: 100%;
    --background-activated:none;
    height: 40px;

}
.modify_header{
    --background: var(--beige)
}

ion-select::part(icon){
    display: none !important;
}
.objectifInter_time_date_text{
    color: var(--blueFlit);

}
.objectifInter_time{
    width: 40%;
}
.objectifInter_modal{
    --width:90%;
    --height:350px;
    color: var(--blueFlit);
}

.objectifInter_notValid{
    color: var(--grey);
    font-size: 30px;

}
.objectifInter_valid{
    color: var(--lightGreenFlit);
    font-size: 30px;

}
.objectifInter_add_content{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;

}
.objectifInter_content_add{
    background:var(--lightGreenFlit);
    width:60%;
    border-radius: 30px;
    font-size: 14px;
    height: 40px;
    color: white;
}
.objectifInter_content_add_icon{
    color: var(--lightGreenFlit);
    font-size: 30px;
}

@media screen and (min-width: 1024px) {

}
@media screen and (min-width: 320px) {
    .objectifInter_list_obj_text_date{

        width: 40%;
        font-size: 12px;
    }
    .objectifInter_list_obj_text_distance{
        background-color: white;
        border-radius: 20px;
        text-align: center;
        padding: 15px;
        width: 40%;
        font-size: 12px;
    }
    .objectifInter_select{

        width: 40%;
        height: 40px;
    }

}

.objectifInter_empty_left{
    width: 5%;
    height: 10Px;
}

.objectifInter_empty_right{
    width: 12%;
    height: 10Px;
}


@media screen and (min-width: 390px) {
    .objectifInter_content_add{

        font-size: 16px;

    }
    .objectifInter_select{
        font-size: 15px;
    }
    .objectifInter_list_obj_text_date{

        width: 40%;
        font-size: 16px;
    }
    .objectifInter_list_obj_text_distance{
        background-color: white
    ;
        border-radius: 20px;
        text-align: center;
        padding: 15px;
        width: 40%;
        font-size: 16px;
    }
    .objectifInter_select{

        width: 40%;
    }

}
@media screen and (min-width: 410px){

}