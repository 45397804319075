.disponibility_list {
    display: flex;
    justify-content: space-between;
}

.disponibility_list_item {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.disponibility_list_label{
    font-weight: bold;
}
.disponibility_checkbox_box{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--greenNew);
    height: 45px;
    width: 35px;
    border-radius: 15px;
}
.disponibility_list_item_checkbox{
    --border-radius: 30px;
    width: 20px;
    height: 20px;
}

.disponibility_button{
    width:40%;
    margin-top: 20px;
    --background: linear-gradient(
            90deg,
            rgba(0, 116, 115, 1) 0%,
            rgba(0, 116, 115, 1) 35%,
            rgba(101, 183, 111, 1) 100%
    );
    --border-radius: 30px;
    font-weight: bold;
}

/* Checked*/
.disponibility_checkbox_box_checked{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--lightGreenFlit);
    height: 45px;
    width: 35px;
    border-radius: 15px;
    --checkmark-width:20px
}
.disponibility_list_item_checkbox_checked{
    --border-radius: 15px;
    width: 25px;
    height: 40px;
    --checkmark-width: 3px;
}
@media screen and (min-width: 390px) {
    .disponibility_checkbox_box{

        height: 55px;
        width: 45px;
        border-radius: 15px;
    }
    .disponibility_list_item_checkbox{
        --border-radius: 15px;
        width: 20px;
        height: 20px;
    }
    .disponibility_checkbox_box_checked{

        height: 55px;
        width: 45px;

    }
    .disponibility_list_item_checkbox_checked{
        width: 35px;
        height: 50px;
        --checkmark-width: 3px;
    }
}